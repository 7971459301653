import React from "react"
import { graphql } from 'gatsby'
import Img from "gatsby-image"

import Layout from "../components/layout"
import styled from "@emotion/styled"
import { Global, css } from "@emotion/react"
import {color, spacing, screen} from "../constants"

import useToggle from "../components/useToggle"
import Footer from "../components/footer"

export default function Kontakt({data}) {
  const [dateOpen, toggleDate] = useToggle();
  const [ticketOpen, toggleTicket] = useToggle();
  
  
  return (
    <Layout title="Kontakt">
      <main css={css`
        background-color: ${color.light};
        flex: 1 1 980px;
        
        padding: ${spacing.xs};
        margin: 0;

        @media (min-width: ${screen.xsPhone}) {
          padding: ${spacing.medium};
          margin: 0 ${spacing.small};
        }
        @media (min-width: ${screen.tablet}) {
          padding: calc(0.75*${spacing.big}) ${spacing.big};
          margin: 0 ${spacing.medium};
        }
      `}>
        <h1>Kontakt</h1>
        <p>
            GML Kreuzlingen<br/>
            c/o Manuela Eichenlaub, Präsidentin<br/>
            Weinstrasse 3<br/>
            CH-8280 Kreuzlingen<br/>
            Tel.: <a href="tel:+41767477739" css={css`text-decoration: none; color: ${color.secondaryDark};`}>+41 76 747 77 39</a><br/>
            E-Mail: <a href="mailto:info@gml-kreuzlingen.ch" css={css`text-decoration: none; color: ${color.secondaryDark};`}>info@gml-kreuzlingen.ch</a><br/><br/>

            Bankverbindung:<br/>
            Raiffeisenbank Tägerwilen<br/>
            IBAN: CH74 8080 8006 5743 0113 3<br/>
            BIC:   RAIFCH22<br/><br/>
        </p>
        <h5 style={{fontSize: '1.15em', margin: '2em 0 1em 0'}}>Webseite & Programmierung</h5>
        <a href="https://lacreme.digital" target="_blank" rel="noreferrer">
        <div css={css`max-width: 250px; margin-left: -10px;`}><Img fluid={data.file.childImageSharp.fluid} className="lacreme-logo" alt="LACREME digital" title="LACREME digital" /></div>
        </a>
        <a href="https://lacreme.digital" target="_blank" rel="noreferrer" css={css`color: ${color.secondary}; text-decoration: none;`}><span style={{justifySelf: 'flex-start'}}>www.lacreme.digital</span></a>
        <br/><br/>

        <h5 style={{fontSize: '1.15em', margin: '2em 0 1em 0'}}>Datenschutzerklärung</h5>
        <p>
          Verantwortlich im Sinne der Datenschutzgesetze ist der Webseiten-Inhaber, der unter "Kontakt" aufgeführt ist.
          <br/><br/>
          <b>AWStats</b><br/>
          Zur statistischen Auswertung der Webseite kommt der Open Source Log File Analyzer AWStats zum Einsatz. Dieser wertet die Logdateien des Webservers aus. Zwar sind diese Daten in der Regel nicht bestimmten Personen zuordenbar, dennoch enthalten die Logdateien Hostnames und IP-Adressen der Besucheranfragen. Diese Daten werden nicht an Dritte übermittelt und nicht mit anderen Datenquellen zusammengeführt sowie nach der statistischen Auswertung gelöscht. Die Logdateien befinden sich auf Servern in der Schweiz, wodurch eine serverseitige Übermittlung der Daten ins Ausland vermieden wird.
          <br/><br/>
          <b>Mailchimp (Newsletter)</b><br/>
          Bei einer Anmeldung über unser Newsletter-Formular speichern wir Ihre E-Mail Adresse und Ihren Namen. Weitere Informationen und die Datenschutzerklärung von Mailchimp finden Sie unter https://mailchimp.com/legal/privacy/ (Rocket Science Group, LLC, 675 Ponce De Leon Ave NE #5000, Atlanta, GA 30308, USA)
        </p>
        
        <Footer marginTop={true} />
      </main>
    </Layout>
  )
}

export const query = graphql`
  query {
    file(relativePath: { eq: "lacreme-logo.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 300) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`